@import '@sassvars';

.divider {
  border-width: 0;
  height: 1px;
  color: $gray-light;
  background: $gray-light;

  width: (100%);
}
