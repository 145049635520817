@import '@sassvars';

.wrapper {
  display: flex;
  flex-flow: column;
  // background: url('/images/lp_background.svg') no-repeat center top;
  // background-size: cover;
  // object-fit: cover;
  width: 100%;
  min-height: 100vh;
  padding: 1.8rem;
  // text-align: center;

  @include tablet {
    padding: 1rem;
    overflow-y: hidden;
  }
}

.heroSubheader {
  color: $gray;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 28em;
  // text-align: center;
  letter-spacing: 0.025rem;

  @include tablet {
    font-size: 1.1rem;
    letter-spacing: 0.025rem;
  }
}

.header {
  letter-spacing: 0.1rem;
  font-size: 4.4rem;
  color: $black;
  // text-align: center;

  b:nth-child(n) {
    color: #3dc08d;
  }

  @include tablet {
    letter-spacing: 0.05rem;
    font-size: 1.65rem;
    margin-bottom: 1rem;
    // text-align: center;
  }

  @include mobile {
    font-size: 1.4rem;
  }
}

.subheader {
  color: $black;
  font-size: 30px;

  @include tablet {
    font-size: 22px;
  }
}

.hero {
  display: flex;
  flex-flow: column nowrap;
  // align-items: center;
  justify-content: center;
  height: 45vh;
  min-height: 30rem;
  width: 70%;
  margin: auto;

  @include tablet {
    padding: 1rem;
    padding-bottom: 0rem;
    height: 40vh;
    min-height: 26rem;
    margin-bottom: 0;
    margin-left: 0%;
  }

  @include mobile {
    width: 100%;
  }
}

.fullWidthMessage {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 45vh;
  min-height: 30rem;

  margin: auto;
  text-align: center;

  @include tablet {
    padding: 0rem;
    padding-bottom: 0rem;
    height: 25vh;
    margin-bottom: 0;
    margin-left: 0%;
  }
}

.fullWidthMessageSubheader {
  color: $gray;
  font-size: 2rem;
  font-weight: 400;
  max-width: 70%;
  margin: auto;
  text-align: center;
  letter-spacing: 0.025rem;

  @include tablet {
    font-size: 1.1rem;
    letter-spacing: 0.025rem;
    max-width: 100%;
  }
}

.flowGrid {
  display: 'grid';
  grid-template-rows: max-content auto auto auto max-content;
  grid-template-columns: 300px minmax(350px, 600px) 300px;
}

.productImg {
  display: block;
  // max-width: 80em;
  // margin: 1rem 0;
  // height: auto;
  // align-self: right;

  box-shadow: $box-shadow--heavy;
  border-radius: 26px;

  @include mobile {
    // display: none;
  }
}

.productFeaturesContainer {
  display: flex;
  flex-direction: column;
  gap: 8em;
  margin-bottom: 8em;

  @include mobile {
    gap: 6em;
    margin-bottom: 4em;
  }
}

.vectorImg {
  @extend .productImg;
  box-shadow: none;
  width: 40%;
  height: auto;

  @include mobile {
    display: inherit;
  }
}

.pngImg {
  @extend .productImg;
  // box-shadow: none;
  width: 60%;
  height: auto;
  // border: 1px solid $gray-light;
  border-radius: 20px;

  @include mobile {
    display: inherit;
  }
}

.section {
  display: flex;
  flex-flow: column nowrap;
  padding: 4rem 1rem;

  @include tablet {
    padding: 1rem;
  }

  @include mobile {
    padding: 0.75rem;
  }
}

.triGrid {
  display: flex;
  max-width: 80em;
  margin: 0 auto;
  flex-flow: row nowrap;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  & > div {
    width: 30%;
  }

  @include tablet {
    flex-flow: column nowrap;

    & > div {
      width: 100%;
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  padding: 1.5rem;
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
  box-shadow: $box-shadow--heavy;
  border-radius: 50%;
  color: $blue-primary;
}

.iconSubText {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.text {
  font-size: 18px;
  color: $gray;
  line-height: 1.3;
  margin-top: 0.25rem;

  &Lg {
    @extend .text;
    font-size: 26px;

    @include mobile {
      font-size: 22px;
    }
  }
}

.sources {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 1rem;
  border: 1px solid $gray-light;
}

.source {
  color: $gray;
}

.imgSection {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 100rem;
  justify-content: space-between;

  @include mobile {
    flex-flow: column-reverse nowrap;
  }

  & > div {
    width: 38%;

    @include mobile {
      width: 100%;
    }
  }

  & > img {
    width: 55%;
    max-height: 55vh;
    object-fit: scale-down;

    @include mobile {
      width: 100%;
    }
  }

  &Odd {
    @extend .imgSection;
    flex-flow: row-reverse wrap;

    @include mobile {
      flex-flow: column-reverse wrap;
    }
  }
}

.ctaSection {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: 2em;
}

.integrations {
  width: 70%;
  margin: 1em auto;
  // margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: auto;
  gap: 3%;
  flex-wrap: wrap;

  @include mobile {
    width: 100%;
  }
}

.integrationLogo {
  width: 10%;
  @include mobile {
    width: 20%;
  }
}

// .integrations {
//   display: flex;
//   flex-flow: row nowrap;
//   justify-content: center;
//   align-items: center;
//   width: 100%;

//   @include mobile {
//     flex-flow: row wrap;
//   }

//   &Img {
//     filter: grayscale(100%);
//     height: 3rem;
//     margin: 1rem;

//     @include tablet {
//       height: 2.5rem;
//       margin: 0.75rem;
//     }

//     @include mobile {
//       height: 1.75rem;
//       margin: 1rem;
//     }
//   }
// }
