@import '@sassvars';

.topMenu {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 8%;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
}

.wrapper {
  display: flex;
  flex-flow: column;
  // background: url('/images/lp_background.svg') no-repeat center top;
  background-color: $white;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  text-align: left;
}
