@import '@sassvars';

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include mobile {
    width: 100%;
  }
}

.testimonialRow {
  display: flex;
  flex-direction: row;
  margin: 2em auto;
  gap: 1em;

  @include mobile {
    flex-direction: column;
    gap: 1em;
  }
}

.sectionHeader {
  align-self: center;
  font-size: 2rem;
  @include tablet {
    font-size: 1.5rem;
  }
}
