@import '@sassvars';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3rem;

  @include tablet {
    margin-top: 0;
    margin-left: 0;
    width: 100%;
  }

  @include mobile {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
  }
}

.trident {
  @include mobile {
    padding: 0;
    width: 65%;
    margin-top: -3rem;
    transform: rotate(90deg);
  }
}

.dataSources {
  display: flex;
  gap: 1em;
  flex-direction: column;
  padding: 0 1em;
  width: 16em;
  height: 30em;
  justify-content: space-between;

  @include mobile {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    height: auto;
  }
}

.source {
  @include mobile {
    height: auto;
    width: 40%;
  }
}

.rightSection {
  display: flex;
  flex-direction: column;
  width: 80%;

  @include mobile {
    width: 100%;
  }
}

.features {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 1rem;
  @include mobile {
    display: none;
  }
}

.productImage {
  display: block;
  overflow: hidden;
  height: 90%;
  position: relative;
  box-shadow: $box-shadow--heavy;
  border-radius: 20px;

  @include mobile {
    margin-top: -5rem;
    border-radius: 15px;
  }
}
