@import '@sassvars';

.container {
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: auto;
  gap: 5%;

  @include tablet {
    flex-direction: column;
  }

  @include mobile {
    width: 100%;
  }
}

.containerReversed {
  @extend .container;
  flex-direction: row-reverse;
}

.textContainer {
  margin-top: 0;
  width: 30%;

  @include tablet {
    width: 100%;
  }
}

.headline {
  font-size: 2rem;
  @include tablet {
    font-size: 1.5rem;
  }
}

.name {
  font-size: 1rem;
  color: $gray;
}

.subHeadline {
  font-size: 1.25rem;
  @include tablet {
    font-size: 1.1rem;
  }
}

.descriptionContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bullet {
  width: 5%;
}

.descriptionItem {
  width: 95%;
  font-size: 1.1rem;
  margin-left: 1rem;

  @include tablet {
    width: 100%;
    font-size: 1rem;
  }
}

.image {
  width: 55%;
  margin: auto;

  @include tablet {
    margin-top: 2em;
    width: 100%;
  }
}
