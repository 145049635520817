@import '@sassvars';

.card {
  background-color: $white;
  margin: 0.25em 0;
  padding: 1em;
  border: none;
  border-radius: 0.25em;
  box-shadow: $box-shadow--std;
}

.cardextra {
  background-color: $white;
  margin: 0.25em 0;
  padding: 1em 1em 2em 1em;
  border: none;
  border-radius: 0.25em;
  box-shadow: $box-shadow--std;
  height: 100%;
  width: 100%;
}
.ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}

.title {
  font-size: 16px;
}

.header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
