@import '@sassvars';

.name {
  font-weight: 200;
}

.headshot {
  border-radius: 50px;
  overflow: hidden;
  height: 80px;
  width: 80px;

  @include mobile {
    height: 50px;
    width: 50px;
  }
}

.personInfo {
  display: flex;
  flex-direction: column;
  margin: auto 0.6em;
  gap: 0.25em;

  @include mobile {
    margin: auto 0.5em;
  }
}
