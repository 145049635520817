@import '@sassvars';
@import './ProductFeature.module.scss';

.container {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 1em;

  @include mobile {
    width: 100%;
  }
}

.headline {
  @extend .headline;
  width: 60%;
}

.sectionImage {
  margin: auto;
  display: block;
  overflow: hidden;
  height: 90%;
  position: relative;
  box-shadow: $box-shadow--heavy;
  border-radius: 20px;

  @include mobile {
    border-radius: 15px;
  }
}

.description {
  @extend .subHeadline;
  width: 80%;
  min-height: 6vh;
  margin-top: 1em;

  @include mobile {
    min-height: 12vh;
    width: 100%;
  }
}

.selectorContainer {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.selectors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.description {
  display: flex;
  flex-direction: column;
}
