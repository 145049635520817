@import '@sassvars';

.wrapper {
  height: auto;
  margin: auto;
  width: 65%;
  @include tablet {
    padding-bottom: 0rem;
    width: 100%;
    margin-bottom: 0;
  }
}
.header {
  padding-top: 2rem;
  font-size: 1.5rem;
  font-weight: lighter;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;

  @include mobile {
    font-size: 1.25rem;
    letter-spacing: 0.005rem;
  }
}

.logo {
  width: 15%;
  align-self: center;
  display: block;
  @include mobile {
    width: 20%;
  }
}

.row {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: auto;
  gap: 3%;
  flex-wrap: wrap;
}
