@import '@sassvars';
@import './ProductFeature.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 70%;
  margin: auto;
  gap: 3em;
  justify-content: space-between;

  @include mobile {
    width: 95%;
    gap: 1.75em;
  }
}

.feature {
  width: 45%;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 45%;
  }
}

.featureTitle {
  color: $gray-medium;
  font-size: $font-base;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.featureDescription {
  font-weight: lighter;
}

.headline {
  @extend .headline;
  margin: 1em auto;
  //   margin-top: 2em;
  //   margin-bottom: 1em;
}
